import Col from 'antd/es/col';
import './styles/footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <Col key="privacy" span={24}>
                <span><small><a target="_blank" title="View Privacy" className="footer_link" href="https://acertusdelivers.com/privacy-policy/" rel="noreferrer">Privacy</a></small></span>
                <span><small className="right">&copy; 2021 ACERTUS All Rights Relentlessly Reserved</small></span>
            </Col>
        </footer>
    )
}

export default Footer
