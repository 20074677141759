import './styles/404.css'
const NotFound=(props:any)=>{
   // console.log(props.orderId.id);
    
    return (
        <div className="page-404-wrapper">
        <div className="header">:) Oops! </div>
        
        <p className="description">
        Order details currently unavailable.<br/>
        Please try again after some time! 
        </p>
        
        </div>
    )
}
export default NotFound