import Col from "antd/es/col"
import Row from "antd/es/col";
import useOrder from "./useOrder";
import Moment from 'react-moment'
import {
    BrowserView,
    isBrowser,
    isIE,
    isMacOs,
    isTablet
} from "react-device-detect";

const font16 = isMacOs || isIE? 'fs-16' : 'fs-12'
const font20 = isMacOs || isIE? 'fs-20' : ''
const OrderDetails = (props: any) => {
    const { orderDetails } = useOrder();
    let orderData = orderDetails[0]
    let status = orderData.STATUS.toLocaleLowerCase()
    return (
        <>{isBrowser ?
            <BrowserView>
                
                <Row className="details-container">
                    <table width="100%" >
                        <tr >
                            <td width="50%">
                                <Col span={22} className="column-details">
                                    <label className={font20}>Vehicle:</label>
                                    <p><small className={font16}>{orderData.MODEL_YEAR}  {orderData.MAKE}  {orderData.MODEL}<div>
                                    {orderData.VIN} </div></small></p>
                                    <label className={font20}>Origin:</label>
                                    <p><small className={font16}><div>{orderData.O_LINE_1}</div>{orderData.O_LINE_2 ? <div>{orderData.O_LINE_2}</div> : ''}{orderData.O_LINE_3 ? <div>{orderData.O_LINE_3}</div> : ''}</small></p>
                                    <label className={font20}>Destination:</label>
                                    <p><small className={font16}><div>{orderData.D_LINE_1}</div>{orderData.D_LINE_2 ? <div>{orderData.D_LINE_2}</div> : ''}{orderData.D_LINE_3 ? <div>{orderData.D_LINE_3}</div> : ''}</small></p>
                                </Col>
                            </td>
                            <td width="50%" className="time-td">
                                <Col span={16} className="column-details time-wrapper">
                                    <Col className="delivery-status">
                                    <label className={font20}>{status === 'delivered' || status === 'completed' ? 'Delivered : ' : 'Estimated Delivery : '}</label>
                                        {
                                            orderData.DELIVERED_UTC_ON === null ?
                                                orderData.DELIVERY_ETA_UTC_ON === null ?
                                                    <p>
                                                        Will be updated once assigned
                                                    </p> :
                                                    <p>
                                                        <small style={{paddingRight:"5px" }} className={font16}>
                                                            <Moment format="dddd">
                                                                {orderData.DELIVERY_ETA_UTC_ON}
                                                            </Moment>
                                                        </small>
                                                        {/* <br /> */}
                                                        <b>
                                                            <Moment format="MMMM DD, YYYY">
                                                                {orderData.DELIVERY_ETA_UTC_ON}
                                                            </Moment>
                                                        </b>
                                                    </p>
                                                : <p>
                                                    <small style={{paddingRight:"5px",paddingLeft:"2px"}} className={font16}>
                                                        <Moment format="dddd">
                                                            {orderData.DELIVERED_UTC_ON}
                                                        </Moment>
                                                    </small>
                                                    {/* <br /> */}
                                                    <b>
                                                        <Moment format="MMMM DD, YYYY">
                                                            {orderData.DELIVERED_UTC_ON}
                                                        </Moment>
                                                    </b>
                                                </p>
                                        }
                                    </Col>

                                </Col>
                            </td>
                        </tr>
                    </table>
                </Row>
              
            </BrowserView>
            : <><Row className="details-container">
                <table width="100%" className={isTablet?"":'m-r-20'} >
                    <tr >
                        <td width="50%">
                            <Col span={12} className="order-details-view" >
                            <label className={font20}>Vehicle:</label>
                                    <p><small className={font16}>{orderData.MODEL_YEAR}  {orderData.MAKE}  {orderData.MODEL}<div>
                                    {orderData.VIN} </div></small></p>
                                <label>Origin:</label>
                                <p><small><div>{orderData.O_LINE_1}</div>{orderData.O_LINE_2 ? <div>{orderData.O_LINE_2}</div> : ''}{orderData.O_LINE_3 ? <div>{orderData.O_LINE_3}</div> : ''}</small></p>
                                <label>Destination:</label>
                                <p><small><div>{orderData.D_LINE_1}</div>{orderData.D_LINE_2 ? <div>{orderData.D_LINE_2}</div> : ''}{orderData.D_LINE_3 ? <div>{orderData.D_LINE_3}</div> : ''}</small></p>
                            </Col>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%" className="time-td">
                            <Col span={12} className="column-details time-wrapper">
                                <Col className="delivery-status">
                                <label>{status === 'delivered' || status === 'completed' ? 'Delivered:' : 'Estimated Delivery:'}</label>
                                    {
                                        orderData.DELIVERED_UTC_ON === null ?
                                            orderData.DELIVERY_ETA_UTC_ON === null ?
                                                <p>
                                                    Will be updated once assigned
                                                </p> :
                                                <p>
                                                    <small className={font16}>
                                                        <Moment format="dddd">
                                                            {orderData.DELIVERY_ETA_UTC_ON}
                                                        </Moment>
                                                    </small>
                                                    <br />
                                                    <b>
                                                        <Moment format="MMMM DD, YYYY">
                                                            {orderData.DELIVERY_ETA_UTC_ON}
                                                        </Moment>
                                                    </b>
                                                </p>
                                            : <p>
                                                <small className={font16}>
                                                    <Moment format="dddd">
                                                        {orderData.DELIVERED_UTC_ON}
                                                    </Moment>
                                                </small>
                                                <br />
                                                <b>
                                                    <Moment format="MMMM DD, YYYY">
                                                        {orderData.DELIVERED_UTC_ON}
                                                    </Moment>
                                                </b>
                                            </p>

                                    }
                                </Col>

                            </Col>
                        </td>
                    </tr>
                   
                </table>
            </Row>
            </>}

        </>
    )
}

export default OrderDetails
