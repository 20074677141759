import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InitialOrder from './initial/initialOrder.json'
import IOrder from "./type/IOrder";


export interface IOrderState {
  orderDetails: Array<IOrder>;
  isLoading:boolean;
  isData:boolean;
}

const initialState: IOrderState = {
  orderDetails:InitialOrder,
  isLoading:true,
  isData:false
};

const OrdersSlice = createSlice({
  name: "OrderDetails",
  initialState: initialState,
  reducers: {
    setIsData:(state, action: PayloadAction) => {
    state.isData = true;      
  },
    setIsLoading:(state, action: PayloadAction) => {
      state.isLoading = false;      
    },
    setOrderDetails: (state, action: PayloadAction<Array<IOrder>>) => {
      state.orderDetails = action.payload;
    },    
  },
});

const OrderReducer = OrdersSlice.reducer;
const OrderActions = OrdersSlice.actions;
export { OrderReducer, OrderActions };
