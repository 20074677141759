import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/create-store';
import { OrderActions } from '../../redux/OrderStatus';

/**
 * @description custom hook which replaces old way of servie calls.
 */
const useOrder = () => {

  const dispatch = useDispatch();

  // redux state values
  const { orderDetails, isLoading, isData } = useSelector((state: RootState) => state.orderdata);

  /**
   * @description fetches order data fom server and store in redux state
   * @param filterText search string
   */
  const getOrderData = async (orderId: any) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/get-order-status`, {
        params: {
          search: orderId.id
        },
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        }
      })
      const result = response.data;
      if (result.assertion) {
        dispatch(OrderActions.setIsData())
        dispatch(OrderActions.setIsLoading())
        if (result.data)
          dispatch(OrderActions.setOrderDetails(result.data));
      } else {
        dispatch(OrderActions.setIsLoading())
      }
      return response.data;
    } catch (error: any) {
      dispatch(OrderActions.setIsLoading())  
      console.error(error);      
    }
  };
  return {
    orderDetails, isLoading, getOrderData, isData
  }
}

export default useOrder;