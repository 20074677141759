import './styles/header.css';
// @ts-ignore
import logo from '../assets/acertus.jpg'
import { Header } from 'antd/lib/layout/layout';
const OrderHeader=()=>{
    return(
        <Header className="header-order-div">
                <img src={logo}  className="brand-logo" alt="Vinlocity logo"/>
        </Header>
    )
}
export default OrderHeader