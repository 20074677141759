/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from 'react';
import Col from "antd/es/col"
import Row from "antd/es/row";
import { Content } from "antd/lib/layout/layout"
import OrderHeader from './OrderHeader';
import { OrderTimeline } from './OrderTimeline'
import OrderDetails from './OrderDetails';
import Footer from './Footer';
import useOrder from './useOrder';
import { Space, Spin } from 'antd';
import { useParams } from "react-router-dom"
import NotFound from '../../pages/NotFound';
import './styles/timeline.css'
import {
    MobileView,
    isMobile,
    isMacOs,
    isIE
} from "react-device-detect";
import { getResolution } from './utility/common';

const Order: FC = () => {
    const { isData, isLoading, orderDetails, getOrderData } = useOrder();
    // get vinlocity id from url params
    const orderid: string = useParams();
    React.useEffect(() => {
        getOrderData(orderid).then((result) => {

        });
    }, [orderid]);

    //if is loading show loader    
    if (isLoading) {
        return (
            <>
                <OrderHeader />
                <Content>
                    <Row className={isMacOs || isIE ? 'wrapper_row h-v-82' : 'wrapper_row'}>
                        <Space size="middle">
                            <Spin size="large" />
                        </Space>
                    </Row>
                </Content>
                <Footer />
            </>
        )
    } else {
        //if response have the data or not 
        if (orderDetails[0] !== null && isData) {
            return (
                <>
                    <OrderHeader />
                    <Content>
                        <Row className="order-details-div" justify='center'>
                            <h1 className={isMacOs || isIE ?"center header-title mt-20":"center header-title"} >Order Status Tracking!</h1>
                            <hr />
                            <p className={isMacOs || isIE ?"center header-subtitle mt-20 mb-20":"center header-subtitle"}>Thank you for entrusting ACERTUS with your vehicle</p>
                        </Row>
                        <Row className={getResolution('wrapper_row')}>
                            <Col xl={7} md={10} sm={24} xs={24} className={isMacOs || isIE ?"timeline-div h-350":"timeline-div h-280"} >
                                <OrderTimeline />
                            </Col>
                            <Col xl={17} md={14} sm={24} xs={24} className="order-content">
                                <OrderDetails />
                            </Col>
                            <Col span="24" className={isMacOs || isIE ? "t-60" : "help-div t-20"}>
                                <h4 className={isMacOs || isIE ? "help-title center fs-20" : "help-title center"}>Need Assistance?</h4>
                                <p className={isMacOs || isIE ? "center fs-17" : "center"}>Call 314-334-3400 to speak with our representative.</p>
                            </Col>
                        </Row>
                        
                    </Content>
                    <Footer />
                </>
            )
        } else {
            return (
                <>
                    <OrderHeader />
                    <NotFound orderId={orderid} />
                </>
            )
        }
    }


}


export default Order;