import Col from "antd/es/col"
import Row from "antd/es/row";
import { FC } from "react";
import { isBrowser, isIE, isMacOs } from "react-device-detect";
import { FaCarSide, FaHome, FaShoppingCart } from 'react-icons/fa';
import Moment from 'react-moment';
import IOrder from "../../redux/type/IOrder";
import useOrder from "./useOrder";

const checkpoints = [1, 2, 3];
const notAvailable: string = "Data Not Available";
const font14 = isBrowser ? 'fs-14' : ''
const font12 = isBrowser ? 'fs-12' : ''
/**
 * @description timeline component
 * @returns progress bar
 */
export const OrderTimeline: FC = (props: any) => {
    //react hook to get redux state for orderdetails 
    const { orderDetails } = useOrder();
    var timelineWrapper = document.createElement('Row');
    timelineWrapper.className = "delivery-timeline-section";
    return (
        <Row className="delivery-timeline-section">
            <Col xl={24} className="delivery-timeline-section">
                {/* calling checkpoint 1, 2 and 3 */}
                {checkpoints.map(
                    (checkpoint) => GetMessageBody(orderDetails[0], checkpoint)
                )}
            </Col>
        </Row>
    )
}

//get Status
const getStatus = (data: IOrder) => {
    let status = data.STATUS.toLocaleLowerCase()
    return status
}
//create message body with data
const GetMessageBody = (data: IOrder, index: number) => {
    return (
        <>
            <Row key={index} className="timeline-message-body">
                {index !== 3 ? <div className="ant-timeline-item-tail"></div> : ''}
                <Col className={index === 1 ? "timeline-target" : index === 2 ? "timeline-target border-orange" : "timeline-target border-gray"}>
                    <Col className="timeline-target-circle">{index}</Col>
                </Col>
                <Col xl={24} className="timeline-content">
                    {GetTimelineContent(data, index)}
                </Col>
            </Row>
        </>
    )
}

//get Timeline Message Body for checkpoints
const GetTimelineContent = (data: IOrder, index: number) => {
    var status = getStatus(data)
    return (
        <Row className={getClassName(index, status)}>
            <Col xl={24} className="row-timeline">
                <Col xl={3} md={3} sm={4} xs={4} className="timline-avatar-div">
                    {index === 1 ?
                        <FaShoppingCart className="shopping-cart-avatar" /> :
                        index === 2 ?
                            <FaCarSide className={
                                status === 'shipped'
                                    || status === 'enroute'
                                    || status === 'delivered'
                                    || status === 'completed'
                                    ? "car-icon orange" :
                                    "car-icon lightgray"} /> :
                            <FaHome className={status === 'delivered' || status === 'completed' ?
                                'home-avatar' :
                                "home-avatar lightgray"}
                            />}
                </Col>
                <Col xl={21} md={21} sm={20} xs={20} className={getMsgContentClassName(index, status)}>
                    {getMessage(data, index)}
                </Col>
            </Col>
        </Row>
    )
}

//get message body based on index
const getMessage = (data: IOrder, index: number) => {
    var status = getStatus(data)
    return index === 1 ? <>
        <b className={isMacOs || isIE ? font14 : "fs-12"}>The order is received!</b><br />
        <small className={isMacOs || isIE ? font12 : "fs-10"}>on {data.CREATED_UTC_ON ? <Moment format="MM/DD/YYYY">
            {data.CREATED_UTC_ON}
        </Moment> : notAvailable}</small></>
        : index === 2 ?
            <> <b className={isMacOs || isIE ? font14 : "fs-12"}>Your vehicle is en route!</b><br />
                {data.ENROUTE_UTC_ON && (status === "enroute" || status === "delivered") ?
                    <small className={isMacOs || isIE ? font12 : "fs-10"}>on <Moment format="MM/DD/YYYY">
                        {data.ENROUTE_UTC_ON}
                    </Moment> </small> : status === 'enroute' ? <small className={(isMacOs || isIE ? font12 : "fs-10") + " b-400"}>Will be updated once assigned</small> : ""}</> :
            <>
                <b className={isMacOs || isIE ? font14 : "fs-12"}>Your Order has been delivered!</b><br />
                {data.DELIVERED_UTC_ON && status === "delivered" ?
                    <small className={isMacOs || isIE ? font12 : "fs-10"}>on <Moment format="MM/DD/YYYY">
                        {data.DELIVERED_UTC_ON}
                    </Moment> </small> : status === 'delivered' ? <small className={(isMacOs || isIE ? font12 : "fs-10") + " b-400"}>Will be updated once assigned</small> : ""}
            </>
}

const getClassName = (index: number, status: string) => {
    let customClass = ""
    if (index === 1) {
        customClass += status === 'confirmed' || status === 'received' ?
            'timeline-content-body bg-white' :
            'timeline-content-body'
    }
    else if (index === 2) {
        customClass += status === 'shipped' || status === 'enroute' ?
            'timeline-content-body bg-white' :
            'timeline-content-body'
    }
    else {
        customClass += status === 'delivered' ?
            'timeline-content-body bg-white' :
            'timeline-content-body'
    }
    (isMacOs || isIE) && isBrowser ?
        customClass += ' h-78' : customClass += ''
    return customClass
}

const getMsgContentClassName = (index: number, status: string) => {
    let customClass = ""
    if (index === 1) {
        customClass += status === 'confirmed' || status === 'received' || status === 'shipped' || status === 'enroute' || status === 'delivered' || status === 'completed' ?
            'message-body' :
            'message-body lightgray'
    }
    else if (index === 2) {
        customClass += status === 'shipped' || status === 'enroute' || status === 'delivered' || status === 'completed' ?
            'message-body' :
            'message-body lightgray'
    }
    else {
        customClass += status === 'delivered' || status === 'completed' ?
            'message-body' :
            'message-body lightgray'
    }
    return customClass
}