import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Order from "./order_module/components/Order";

const App = () => {
  return (     
    <Switch>
      <Route  path="/order-status/:id" >
         <Order />
      </Route>
      <Redirect from="/" to="/order-status/" /> 
    </Switch>                     
  )
};

export default App;
